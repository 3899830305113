import React from 'react';
import Slick from 'react-slick';
import Icon from '../Icon/Icon';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import './slick.css';
import './slick-theme.css';

const SliderPrevArrow = props => {
  const { className, style, onClick } = props;

  return (
    <div
      data-customleftarrow
      className={className}
      style={{
        ...style,
        display: 'block',
        zIndex: '999',
        marginLeft: '38px'
      }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      onKeyDown={() => onClick()}
      role="button"
      tabIndex="0"
    >
      <Icon symbol="arrowLeftDropshadow" />
    </div>
  );
};

const SliderNextArrow = props => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      data-customrightarrow
      style={{
        ...style,
        display: 'block',
        zIndex: '999',
        marginRight: '38px'
      }}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick();
      }}
      onKeyDown={() => onClick()}
      role="button"
      tabIndex="0"
    >
      <Icon symbol="arrowRightDropshadow" />
    </div>
  );
};

const Slider = props => {
  const { children, dots = true, customArrows, ...rest } = props;

  const customButtonsSetting = customArrows
    ? {
        nextArrow: <SliderNextArrow />,
        prevArrow: <SliderPrevArrow />
      }
    : null;

  const defaultSettings = {
    dots: dots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    ...customButtonsSetting
  };

  const settings = { ...defaultSettings, ...rest };

  return <Slick {...settings}>{children}</Slick>;
};

export default Slider;
