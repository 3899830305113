import React, { useState } from 'react';
import styles from './StockNotificationForm.module.css';

const StockNotificationForm = ({ productId, variantId, storeId, siteId }) => {
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const validateEmail = email => /\S+@\S+\.\S+/.test(email);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailValid(false);
      return;
    }

    const notificationData = {
      storeId: process.env.INSTOCK_NOTIFY_STORE_ID,
      siteId: process.env.INSTOCK_NOTIFY_PROD_SITE_ID,
      productId,
      variantId: variantId || null,
      email
    };

    try {
      const response = await fetch('https://www.instocknotify.net/api/bigcommerce/headless/create', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(notificationData)
      });

      if (response.ok) {
        setIsSubmitted(true);
        setErrorMessage(''); 
      } else {
        setErrorMessage('There was an error submitting your request. Please try again later.');
      }
    } catch (error) {
      setErrorMessage('There was an error submitting your request. Please try again later.');
    }
  };

  return (
    <div className={styles.subscribeForm}>
      {!isSubmitted ? (
        <form onSubmit={handleSubmit}>
          <div className={styles.subscribeDesc}>
            Enter your email to be notified when this item is back in stock:
          </div>
          {!emailValid && (
            <div className={styles.invalidEmail}>Please enter a valid email address.</div>
          )}
          <div className={styles.subscribeFields}>
            <input
              type="email"
              className={styles.subscribeInput}
              value={email}
              onChange={e => {
                setEmail(e.target.value);
                setEmailValid(true); 
              }}
              placeholder='Enter email address'
              required
            />
          </div>
          {errorMessage && (
            <div className={styles.errorMessage}>{errorMessage}</div>
          )}
          <div className={styles.subscribeButton}>
            <button type="submit" className={styles.buttonPrimary}>
              Notify Me
            </button>
          </div>
        </form>
      ) : (
        <div className={styles.subscribeSuccess}>
          <p>Thank you! We will notify you when this item is back in stock.</p>
        </div>
      )}
    </div>
  );
};

export default StockNotificationForm;
