import React, { useCallback, useEffect, useState } from 'react';
import { bcApi } from '../../../helpers/bigcommerce';

import styles from './StockStatus.module.css';

const StockStatus = ({ product, onStatusUpdate }) => {
    const [status, setStatus] = useState({ title: 'In Stock', available: 'in-stock' });
    const [initialLoad, setInitialLoad] = useState(false);

    const updateStatus = useCallback((data) => {
        if (data && 
            typeof data.inventory_tracking !== 'undefined' && 
            typeof data.inventory_level !== 'undefined' && 
            typeof data.inventory_warning_level !== 'undefined' && 
            data.inventory_tracking === 'product'
        ) {
            let _status = {}
            if (data.availability === 'preorder') {
                const releaseDate = new Date(data.preorder_release_date);
                const outputReleaseDate = `${releaseDate.getDate()} ${releaseDate.toLocaleString('default', { month: 'long' })}, ${releaseDate.getFullYear()}`;
                _status = { title: 'Pre-Order', available: 'po-stock', preorder_message: data.preorder_message, preorder_release_date: outputReleaseDate }
            } else if (data.availability === 'available' && data.inventory_level > 5) {
                _status = { title: 'In Stock', available: 'in-stock' }
            } else if (data.availability === 'available' && data.inventory_level > 0 && data.inventory_level <= 5) {
                _status = { title: 'Less than 5 left', available: 'low-stock' }
            } else {
                _status = { title: 'No stock', available: 'no-stock' }
            }
            setStatus(_status)
            if (onStatusUpdate) {
                onStatusUpdate(_status)
            }
        }
    }, [
        onStatusUpdate
    ])

    useEffect(() => {
        if (!initialLoad) {
            updateStatus(product);
            bcApi(`catalog/products/${product.entityId}`, 'GET').then(res => {
                if (res.status === 200) {
                    updateStatus(res.response.data);
                }
            });
            setInitialLoad(true);
        }
    }, [
        updateStatus,
        product,
        initialLoad,
        setInitialLoad,
    ])

    // No stock - qty = 0
    // Less than 5 left - qty between 1 and 5
    // In Stock - qty > 6

    return (<>
        {status && (
            <div className={styles.productAvailable} data-status={status.available}>
                {status.title}
            </div>
        )}
    </>);
};

export default StockStatus;
